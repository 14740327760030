.tables-container {
  display: flex;
  margin: 10px 16px 16px 24px;
  padding-top: 14px;
  width: 97%;
  height: calc(100% - 110px);
  overflow: scroll;
  position: relative;
  box-sizing: border-box;
  .boxes-container {
    min-width: 210px;
    width: 100%;
    margin-left: -6px;
    z-index: 100;
    .box {
      width: 100%;
      height: auto;
      .d-table {
        width: 100%;
        margin-left: -1px;
        .d-thead {
          cursor: pointer;
          position: sticky;
          top: -16px;
          background: white;
          width: 100%;
          .d-h-tr {
            display: flex;
            flex-direction: column;
            text-align: left;
            position: relative;
            .d-svg {
              color: #8b97a2;
              cursor: pointer;
            }
            .d-h-progressBar {
              width: 98%;
              z-index: 10 !important;
            }
          }
        }
        .d-body {
          pointer-events: none;
          z-index: -10;
          .d-tr {
            height: 48px;
            width: 1631px;
            background-color: #ffffff;
            box-shadow: 0 1px 0 0 #dee2e7;
            .d-td {
              color: #1e2429;
              font-family: "Product Sans";
              height: 16px;
              width: 200px;
              font-size: 14px;
              letter-spacing: 0.25px;
              line-height: 16px;
              text-align: left;
              font-weight: normal;
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
}
.hoverItem {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
  margin-right: 20px;
  padding: 10px;
  z-index: 999;
  padding: 4px;
  transition: box-shadow 0.3s ease-out;
}
.headItem {
  height: 18px;
  max-width: 160px;
  width: auto;
  color: #1e2429;
  font-family: "Product Sans";
  font-size: 16px;
  letter-spacing: 0.29px;
  line-height: 18px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.storageItem {
  height: 15px;
  width: 36px;
  color: #8b97a2;
  font-family: "Product Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin: 4px 0px 4px 6px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: 4px;
}
.inferredItem {
  height: 12px;
  width: 45px;
  color: #3c3996;
  font-size: 12px;
  letter-spacing: 0.23px;
  line-height: 14px;
  margin: 4px 0px 9px 6px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: 4px;
}
.menu-options {
  min-width: 140px;
  height: auto;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  .option-container {
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #d8d8d8;
    padding: 0px 2px 0px 2px;
    align-items: center;
    height: 49.4px;
    .menu-title {
      height: 16px;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 16px;
      padding-left: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .menu-icon {
      height: 26px;
      color: #8b97a2;
      padding-left: 8px;
    }
    &:hover {
      background-color: #f0f4f8;
    }
  }
}
.sub-menu {
  position: absolute;
}
.sub-menu-column-type {
  margin-top: 5px;
}
