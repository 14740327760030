.visualization-graph-conatiner {
  box-sizing: border-box;
  height: 90%;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 0 2px 2px 2px;
  background-color: #ffffff;
  margin: 16px 16px 0px 0px;
  overflow-x: hidden;
  .visualization-graph-header-title {
    height: 26px;
    width: 71px;
    color: #1e2429;
    font-family: "Product Sans";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    padding: 24px;
  }
  .visualization-graph-data-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    box-sizing: border-box;
    padding: 24px;
    .visualization-graph-data {
      box-sizing: border-box;
      width: 44%;
      height: 100%;
      margin: 24px;
      .visualization-groutype-title {
        height: 22px;
        width: 100%;
        color: #8b97a2;
        font-family: Helvetica;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #f1f4f8;
      }
      .visualization-groutype-body {
        height: 100px;
        width: 216px;
        .visualization-individual-group {
          display: flex;
          align-items: center;
          margin: 24px 0px 16px 0px;
        }
        .histogram-image-style {
          box-sizing: border-box;
          height: 100px;
          width: 100px;
          border: 1px solid #dbe2e7;
          cursor: pointer;
        }
        .histogram-graph-tittle {
          height: 22px;
          width: auto;
          cursor: pointer;
          color: #8b97a2;
          font-family: Helvetica !important;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 22px;
          margin-left: 24px;
        }
      }
    }
  }
}
