.react-flow__node {
  box-sizing: border-box;
  height: 135px;
  width: 135px;
  border: 4px solid #8b97a2;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  justify-content: center;
  align-items: center;
  cursor: auto;
  .node_option_icon {
    float: right;
    height: 15px;
    cursor: pointer;
    color: #8b97a2;
  }
  .node_image_container {
    width: 100%;
    height: 80px;
    padding: 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  .node_title {
    height: 15px;
    width: 120px;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.selected {
  border: 4px solid #3c3996;
}
.react-flow__handle {
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3c3996;
  &::after {
    background-color: white;
    border-radius: 100%;
    border: 2px solid black;
  }
  .react-flow__handle-connecting {
    opacity: 1;
  }
}
.node_menu {
  height: auto !important;
  width: 165px;
  border-radius: 8px;
  margin: 0px;
  box-shadow: none;
  padding: 0px;
  .node_menu_options {
    height: 50px;
    width: 165px;
    margin: 0px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: #1e2429;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
    &:hover {
      color: #3c3996;
      border-radius: 0px !important;
      background-color: #d8d8d8;
    }
  }
}
