.card-pipeline {
  height: 140px;
  width: 360px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
  box-sizing: border-box;
  padding: 18px 25px 18px 25px;
  margin-right: 30px;
  .title {
    font-family: "Product Sans";
    height: 16px;
    width: 310px;
    color: #5e7081;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    text-transform: capitalize;
    text-align: left;
  }
  .elements {
    height: 100px;
    width: auto;
    display: flex;
    align-items: center;
    .pie-chart {
      margin-left: 0;
    }
    .card-counts {
      width: 250px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .large-value {
        height: 53px;
        width: 310px;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 44px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 53px;
        position: absolute;
        left: 0;
        top: 0;
        text-align: left;
      }
      .count-block {
        height: 70px;
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;

        .values {
          height: 26px;
          width: 60px;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 26px;
          text-align: center;
        }
        .names {
          height: 16px;
          width: 60px;
          color: #5e7081;
          font-family: "Product Sans";
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 16px;
          text-align: center;
          text-transform: capitalize;
        }
      }
    }
  }
}
@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}
