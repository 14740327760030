.popover_menu {
  height: auto;
  width: auto;
  border-radius: 8px;
  margin: 0px;
  box-shadow: none;
  padding: 0px;
  box-sizing: border-box;
  .popover_menu_options {
    box-sizing: border-box;
    height: 50px;
    width: 165px;
    margin-bottom: 2px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: #1e2429;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    box-shadow: 0 1px 0 0 #dee2e7;
    border: none;
    background-color: white !important;
    &:hover {
      color: #3c3996 !important;
      background-color: #f1f4f8;
      box-shadow: 0 1px 0 0 #c8ced5;
      cursor: pointer;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
