.root {
  backdrop-filter: blur(2px);
  .paper {
    height: auto;
    max-height: 350px;
    width: 570px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
    .dialogTitle {
      padding: 24px 40px 20px 30px;
      .dialogHead {
        height: 32px;
        width: 500px;
        color: #242424;
        font-family: Sofia Pro;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
        box-sizing: border-box;
      }
      .dialogSubHead {
        height: 16px;
        width: 500px;
        color: #8b97a2;
        font-family: Product Sans;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin: 7px 0px 0px 0px;
      }
    }
    .dialogContent {
      padding: 0px 25px 0px 25px;
      .config-data-container {
        width: auto;
        height: auto;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .config-data-rows {
          box-sizing: border-box;
          height: 30px;
          width: 100%;
          color: #1e2429;
          font-family: "Product Sans";
          display: flex;
          font-weight: bold;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 16px;
          padding: 0px 24px 0px 24px;
          margin-bottom: 2px;
          border-radius: 5px;
          cursor: pointer;
          &:hover {
            background-color: #4d5796;
          }
        }
        .selected-dataset {
          background-color: #3c3996;
          color: white;
        }
      }
    }
    .dialogActions {
      padding: 8px 20px 14px 30px;
      border-top: 2px solid #dee2e7;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 650px) {
  .root {
    .paper {
      width: 350px;
      .dialogTitle {
        padding: 24px 40px 30px 30px;
        .dialogHead {
          height: 22px;
          width: auto;
          color: #242424;
          font-family: Sofia Pro;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
          box-sizing: border-box;
        }
        .dialogSubHead {
          height: 14px;
          width: auto;
          color: #8b97a2;
          font-family: Product Sans;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          margin: 7px 0px 0px 0px;
        }
      }
    }
  }
}
