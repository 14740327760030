.nav-button {
  position: absolute !important;
  left: -22px;
  top: 67px;
}
.nav-button-mobile {
  color: "#000000";
}
.appbar-head {
  height: 26px !important;
  width: 210px !important;
  color: #1e2429 !important;
  font-family: "Product Sans" !important;
  font-size: 24px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 26px !important;
}
.help-icon {
  height: 25.5px;
  width: 25.5px;
  color: #8b97a2;
}
.profile-icon {
  height: 50px;
  width: 50px;
}
.user-name {
  height: 22px !important;
  width: 101px !important;
  color: #1e2429 !important;
  font-family: "Product Sans";
  font-size: 18px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  margin: 4px 0px 0px 8px !important;
}
.user-role {
  width: auto !important;
  color: #3c3996 !important;
  font-family: "Product Sans";
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0.25px !important;
  line-height: 16px !important;
  margin: 0px 0px 3px 8px !important;
}
