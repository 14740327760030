.config-container-sidebar {
  height: 100%;
  width: 277px;
  background-color: #f1f4f8;
  display: flex;
  align-items: center;
  flex-direction: column;
  .config-container-sidebar-title {
    height: 16px;
    width: 172px;
    color: #1e2429;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    text-align: center;
  }
  .config-container-sidebar-sub-title {
    height: 17px;
    width: 172px;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
  }
  .config-container-sidebar-img {
    height: 208px;
    width: 217.71px;
  }
  .config-container-sidebar-btn {
    height: 40px;
    width: 163px;
    border-radius: 8px;
    background-color: #3c3996;
    box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  .config-container-sidebar-btn-two {
    height: 41px;
    width: 164px;
    border-radius: 8px;
  }
  .config-container-sidebar-btn-icon {
    margin-right: 4px;
  }
  .ex-sidebar-search-container {
    display: flex;
    padding: 8px 13px 8px 16px;
    align-items: center;

    .ex-sidebar-search-checkbox-icon {
      color: #8b97a2;
      margin-right: 8px;
      margin-left: -8px;
    }
    .config-container-search {
      box-sizing: border-box;
      height: 43px;
      width: auto;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      background-color: #f1f4f8;
      display: flex;
      align-items: center;
      .config-container-search-input {
        border: none;
        outline: none;
        background-color: #f1f4f8;
        width: 98%;
      }
      .config-container-search-input-icon {
        margin: 0px 4px 0px 8px;
        color: #8b97a2;
      }
    }
  }
  .ex-sidebar-cards-container {
    width: 100%;
    height: 70%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .ex-sidebar-card {
      height: 82px;
      width: 80%;
      border-radius: 8px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-bottom: 8px;
      padding: 8px;
      .ex-sidebar-card-text {
        width: 150px;
        text-overflow: ellipsis;
        word-break: break-word;
        display: inline-block;
        color: #8b97a2;
        height: 60%;
        overflow: hidden;
        font-family: Helvetica;
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-bottom: 16px;
      }
      .ex-sidebar-card-icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ex-sidebar-card-sub-icons {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .ex-sidebar-card-icon {
            font-size: 14px;
            color: #8b97a2;
            cursor: pointer;
          }
        }
      }
      .ex-sidebar-card-right-icon {
        color: #8b97a2;
        cursor: pointer;
      }
    }
  }
}
