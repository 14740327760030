.dataset-tableHeader {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 10px;
  width: 96%;
  flex-wrap: wrap;
  .tableHeader-title-items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px 10px 10px 0px;
    .tableHeader-tilte-container {
      display: flex;
      align-items: center;
      .tableHeader-tilte {
        height: 26px;
        max-width: 265px;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
        overflow: hidden;
      }
      .tableHeader-value-configure {
        height: 16px;
        margin-top: 4px;
        cursor: pointer;
        max-width: 361.65px;
        color: #32bcd0;
        font-family: "Product Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-left: 18px;
      }
      .tableHeader-settings {
        height: 26.67px;
        width: 25.94px;
        color: #8b97a2;
        margin-left: 16px;
        cursor: pointer;
      }
    }
    .tableHeader-sub-title {
      height: 19px;
      max-width: 340px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 16px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
    }
  }
  .tableHeader-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
