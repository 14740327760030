.draw-graph-container {
  box-sizing: border-box;
  margin-top: 6px;
  width: 98%;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  background-color: #ffffff;
  height: calc(100% - 130px);
  .new-chart-tittle-container {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .new-chart-head {
      display: flex;
      cursor: pointer;
      align-items: center;
      .new-chart-tittle-style-input {
        border: 1px solid #dee2e7;
        outline: none;
        display: inline-block;
        margin-left: 8px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 12px;
        line-height: 16px;
      }
      .new-chart-tittle-style {
        outline: none;
        display: inline-block;
        margin-left: 8px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 12px;
        line-height: 16px;
      }
      .done-icon-style {
        height: 14px;
        width: 14px;
        color: #8b97a2;
        margin-left: 8px;
      }
    }
    .refresh-icons-style {
      margin: 12px 0px 12px 12px;
      cursor: pointer;
    }
    .draw-extra-icons {
      position: absolute;
      right: 12px;
    }
  }
  .group-result-tittle-container {
    height: 30px;
    width: 100%;
    background-color: #3c3996;
    .group-result-tittle-1 {
      height: 30px;
      width: 100%;
      color: #ffffff;
      font-family: "Product Sans" !important;
      font-size: 12px !important;
      letter-spacing: 0.21px !important;
      line-height: 16px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .visualization-graph-container {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }
}
