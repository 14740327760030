.workflow__wrapper {
  height: calc(100vh - 75px);
  width: 100%;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  .workflow_sidebar {
    width: 315px;
    background-color: #ffffff;
    box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
  }
  .workflow_canvas {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    border: 1px dashed #dee2e7;
    background-color: #f1f4f8;
  }
}
.react-flow__edge-path {
  stroke-width: 2 !important;
  stroke: #3c3996 !important;
}
.DialogeContainer {
  height: 170px;
  width: 530px;
  padding: 30px !important;
  box-sizing: border-box;
  .TitleClass {
    height: 32px;
    width: 460px;
    color: #242424;
    font-family: "Sofia Pro";
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 35px;
  }
  .subtitleClass {
    height: 19px;
    width: 460px;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 16px;
    letter-spacing: 0.35px;
    line-height: 19px;
  }
}
.dialogAction {
  box-sizing: border-box;
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-bottom: 12px !important;
  height: 100px !important;
  border-top: 1px solid #dee2e7;
}
.cancelWoBorder {
  border: none;
  box-shadow: none;
  &:hover {
    background: none;
    padding: none;
    border: none;
    color: #3c3996;
    box-shadow: none;
  }
}
