.table-popup-main {
  height: 430px;
  width: 300px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
  position: absolute;
  top: 53px;
  left: 17px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  .popup-heading-container {
    width: 281px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .popup-heading {
      height: 22px;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      padding-left: 10px;
    }
    .close-icon {
      color: #8b97a2;
      margin-right: 6px;
      cursor: pointer;
    }
  }
  .drop-down {
    box-sizing: border-box;
    height: 61px;
    width: 281px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;
    position: relative;
    .drop-down-title {
      height: 15px;
      width: 254.14px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      margin: 10px 6px 0px 20px;
      text-align: left;
    }
    .drop-down-input-holder {
      margin: 0px 10px 13px 16px;
      .drop-down-input {
        outline: none;
        border: none;
        height: 24px;
        caret-color: #3c3996;
        width: 220.14px;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 16px;
      }
    }
    .drop-down-down-icon {
      position: absolute;
      top: 30%;
      right: 10px;
      color: #8b97a2;
    }
  }
  .drop-down-results-container {
    width: inherit;
    height: 250px;
    overflow-x: hidden;
    .drop-down-result {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      width: 100%;
      box-shadow: 0 1px 0 0 #dee2e7;
      .result-title {
        height: 16px;
        width: 216px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-left: 20px;
        text-align: left;
      }
    }
  }
  .table-popup-bottom {
    height: 60px;
    width: 300px;
    transform: scaleY(-1);
    border-radius: 8px 8px 0 0;
    background-color: #ffffff;
    box-shadow: 0 1px 0 0 #dee2e7;
    display: flex;
    align-items: center;
    .sort-from-title {
      height: 16px;
      width: 64px;
      color: #5e7081;
      font-family: "Product Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 16px;
      transform: scaleY(-1);
      margin-left: 20px;
    }
    .sort-order {
      height: 34px;
      width: 150px;
      border-radius: 9px;
      background-color: #f0f5f6;
      margin-left: 18px;
      transform: scaleY(-1);
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      .sort-order-label {
        height: 16px;
        width: 40px;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 16px;
        text-align: center;
        z-index: 2;
        cursor: pointer;
      }
      .sort-order-label-default {
        height: 16px;
        width: auto;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 16px;
        text-align: center;
        z-index: 2;
        cursor: pointer;
      }
      .sort-switch {
        position: absolute;
        box-sizing: border-box;
        height: 30px;
        width: 78.13px;
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        border-radius: 6.93px;
        background-color: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        transition: 0.3s ease all;
        left: 2px;
        cursor: pointer;
      }
      .sort-switch-move {
        position: absolute;
        box-sizing: border-box;
        height: 30px;
        width: 78.13px;
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        border-radius: 6.93px;
        background-color: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        transform: translateX(34px);
        transition: 0.3s ease all;
        cursor: pointer;
      }
    }
  }
}
::placeholder {
  color: #8b97a2;
  font-weight: normal;
}
