.root-statistics {
  backdrop-filter: blur(2px);
  .paper-statistics {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    .statistics-contaniner-btn {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 16px;
      box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
      svg {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }
    }
    .s-popup-title-container {
      width: auto;
      height: auto;
      padding: 24px 30px 24px 30px;
      position: relative;
      .s-popup-title {
        color: #242424;
        font-family: "Sofia Pro";
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }
      .s-popup-sub-title {
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-top: 8px;
      }
      .s-popup-pca-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .s-popup-pca-icon {
          height: 24px;
          width: 24px;
          color: #8b97a2;
          cursor: pointer;
        }
        .s-popup-pca-title {
          color: #242424;
          font-family: "Sofia Pro";
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
          margin-left: 16px;
        }
      }
      .s-popup-close {
        position: absolute;
        top: 24px;
        right: 30px;
      }
    }
    .s-popup-content-container {
      padding: 30px;
      height: auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .s-popup-content-card {
        box-sizing: border-box;
        height: 133px;
        width: 400px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        background-color: #ffffff;
        margin-bottom: 30px;
        padding: 40px 24px 16px 24px;
        cursor: pointer;
        overflow: hidden;

        .s-popup-content-card-title {
          height: 22px;
          width: auto;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
        }
        .s-popup-content-card-content {
          height: auto;
          width: auto;
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          margin-top: 10px;
        }
      }
      .s-popup-content-card:hover {
        border: 1px solid #3c3996;
      }
    }
    .s-popup-content-container-two {
      padding: 0px 30px;
      display: flex;
      justify-content: space-between;
      .s-popup-second-content-card {
        width: 270px;
        .s-popup-second-content-sub-title {
          height: 16px;
          width: 255px;
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          margin-bottom: 10px;
        }
        .s-popup-second-content-title {
          height: 22px;
          width: auto;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
        }
        .s-popup-title-icons-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 8px;
          width: 255px;
          .s-popup-title-icons {
            display: flex;
            align-items: center;
            .s-popup-title-icon {
              margin-left: 20px;
              cursor: pointer;
            }
          }
        }
        .s-popup-second-content-box {
          height: 428px;
          margin-top: 16px;
          box-sizing: border-box;
          .all-columns-conatiner {
            box-sizing: border-box;
            height: 384px;
            width: 255px;
            background-color: #f1f4f8;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 12px;
            padding-bottom: 8px;
            overflow-x: hidden;
            padding-top: 10px;
            .all-columns-conatiner-item {
              box-sizing: border-box;
              height: 33px;
              min-width: 226px;
              width: 85%;
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              background-color: #ffffff;
              color: #8b97a2;
              font-family: "Product Sans";
              font-size: 13px;
              letter-spacing: 0.23px;
              line-height: 16px;
              margin-top: 4px;
              padding: 10px;
              text-align: left;
              cursor: move;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .item-icon {
                height: 14px;
                width: 14px;
                margin-right: 6px;
                color: #515fab;
              }
              .item-icon-other {
                height: 14px;
                width: 14px;
                margin-right: 6px;
                color: #29a475;
              }
            }
          }
          .s-popup-second-content-box-tips {
            display: flex;
            margin-top: 10px;
            width: 255px;
            overflow: hidden;
            word-wrap: break-word;
            margin: 10px;
            .s-popup-second-content-box-tips-icon {
              color: #8b97a2;
              height: 14px;
              width: 14px;
              margin: 2px 5px;
            }
            .s-popup-second-content-box-tips-text {
              width: 223px;
              color: #8b97a2;
              font-family: "Product Sans";
              font-size: 12px;
              letter-spacing: 0.21px;
              line-height: 16px;
            }
          }
        }
        .s-popup-second-content-box-two {
          height: 428px;
          width: 255px;
          margin-top: 16px;
          background-color: #f1f4f8;
          box-sizing: border-box;
          padding-top: 8px;
          .s-popup-drag-text {
            color: #8b97a2;
            font-family: "Product Sans" !important;
            padding-top: 50%;
            text-align: center;
            font-size: 13px !important;
          }
          .s-popup-drag-items {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .s-popup-drag-item {
              display: flex;
              box-sizing: border-box;
              height: 33px;
              width: 236px;
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              background-color: #ffffff;
              margin-top: 4px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 12px 0px 12px;
              .s-popup-drag-item-title {
                color: #8b97a2;
                font-family: "Product Sans";
                font-size: 13px;
                letter-spacing: 0.23px;
                line-height: 16px;
              }
              .s-popup-drag-item-icon {
                color: #8b97a2;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .s-popup-third-content-container {
      width: 100%;
      height: auto;
      margin-top: 30px;
      .s-popup-third-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .s-popup-third-content-title {
          height: 19px;
          width: 59px;
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 16px;
          letter-spacing: 0.35px;
          line-height: 19px;
        }
      }
      .s-popup-third-content-delete {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 14px;
        margin-bottom: 16px;
        .s-popup-third-content-delete-items {
          display: flex;
          height: 18px;
          width: 253px;
          align-items: center;
          justify-content: space-between;
          .s-popup-third-content-delete-items-title {
            height: 16px;
            width: 46px;
            color: #8b97a2;
            font-family: "Product Sans";
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 16px;
          }
          .s-popup-third-content-delete-items-icon {
            cursor: pointer;
          }
        }
      }
    }
    .s-popup-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 20px 14px 20px;
      border-top: 1px solid #dee2e7;
    }
    .radio-label {
      color: #8b97a2;
      font-size: 13px !important;
    }
  }
}
