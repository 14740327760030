.mainCardContainer {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f1f4f8;
  overflow: hidden;
  .line {
    height: 4px;
    width: 97%;
    border-radius: 8px 8px 0 0;
    background-color: #dbe2e7;
    margin-top: 14px;
    .line1 {
      height: 4px;
      width: 191px;
      border-radius: 8px 0 0 0;
      background-color: #3c3996;
    }
  }
  .dataset-container {
    height: 95.81%;
    width: 97%;
    border-radius: 0px 0px 8px 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    overflow-x: hidden;

    .visual-tableData-container {
      box-sizing: border-box;
      display: flex;
      padding: 10px;
      width: 100%;
      height: calc(100% - 92px);
      .visual-tableData-right-container {
        box-sizing: border-box;
        flex-basis: 80%;
        background-color: #f1f4f8;
        .graph-container {
          height: 100%;
          width: 100%;
          padding-right: 0px;
          background-color: #f1f4f8;
          display: flex;
          .graph-box-selector {
            box-sizing: border-box;
            height: 56px;
            width: 56px;
            margin-top: 16px;
            margin-left: 16px;
            border: 1px solid #dbe2e7;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            .graph-box-selector-img {
              width: 56px;
              height: 56px;
              border: 1px solid #d8d8d8;
            }
          }

          .draw-graph-and-axis-container {
            width: 100%;
            height: 100%;
            padding-left: 16px;
            display: flex;
            flex-direction: column;
            border-left: 1px solid #dbe2e7;
            overflow: hidden;
          }
        }
      }
    }
  }
}
#primary_color {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
}

#primary_color::-webkit-color-swatch-wrapper {
  padding: 0;
}
#primary_color::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.drop-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -4px;
  color: #8b97a2;
}

.visual-closeIcon-div {
  position: absolute;
  top: -8px;
  right: 30px;
  cursor: pointer;
  .visual-closeIcon {
    height: 12px;
    width: 12px;
    color: #8b97a2;
  }
}

@media screen and (max-width: 1240px) {
  .mainCardContainer {
    height: 88vh;
    width: 100%;
    .dataset-container {
      overflow: scroll;
      .visual-tableData-container {
        width: 100%;
        height: 84%;
        overflow: scroll;
        .visual-tableData-right-container {
          .graph-container {
            .draw-graph-and-axis-container {
              width: 100%;
              height: 100%;
              padding-left: 16px;
              display: flex;
              flex-direction: column;
              border-left: 1px solid #dbe2e7;
              overflow: scroll;
              .draw-graph-axis-main {
                height: 110px;
                width: 100%;
                display: flex;
                align-items: center;
                overflow-x: scroll;
              }
            }
          }
        }
      }
    }
  }
}
