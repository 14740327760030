.graph-options {
  width: 200px;
  height: 70%;
  padding: 16px;
  margin-top: 16px;
  overflow-x: hidden;
  .graph-options-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    .graph-options-filters-title {
      height: 16px;
      width: 184px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 13px;
      letter-spacing: 0.23px;
      line-height: 16px;
      text-align: left;
    }
    .graph-options-filters-icon {
      margin-top: -2px;
      color: #8b97a2;
    }
  }
  .graph-option-input {
    box-sizing: border-box;
    height: 40px;
    width: 200px;
    border: 1px solid #dbe2e7;
    border-radius: 4px;
    outline: none;
    margin: 10px 0px 10px 0px;
    background-color: #f1f4f8;
  }
  .graph-options-charts {
    border-top: 1px solid #dbe2e7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 16px -16px 0px -16px;

    .graph-options-filters-title {
      height: 16px;
      width: 184px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 13px;
      letter-spacing: 0.23px;
      line-height: 16px;
      text-align: left;
      padding: 16px 0px 0px 16px;
    }
    .graph-options-filters-icon {
      margin-top: -2px;
      color: #8b97a2;
      padding: 16px 0px 0px 16px;
    }
  }
  .details-container {
    width: 100%;
    .addChart-btn {
      height: 32px;
      width: 85%;
      border-radius: 8px;
      box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
      margin-bottom: 16px;
      svg {
        margin-right: 12px;
      }
    }
    .chart-details {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      cursor: pointer;
      .chart-details-name {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
      }
      .details-delete-icon {
        cursor: pointer;
      }
    }
    .chart-details-selected {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      cursor: pointer;
      .chart-details-name {
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #3c3996;
        font-family: "Product Sans";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        font-weight: bold;
      }
      .details-delete-icon {
        cursor: pointer;
        color: #3c3996;
      }
    }
  }
}
