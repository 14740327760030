.mainCardContainer {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f1f4f8;
  overflow: hidden;
  .line {
    height: 4px;
    width: 97%;
    border-radius: 8px 8px 0 0;
    background-color: #dbe2e7;
    margin-top: 14px;
    .line1 {
      height: 4px;
      width: 191px;
      border-radius: 8px 0 0 0;
      background-color: #3c3996;
    }
  }
  .dataset-container {
    height: 95.81%;
    width: 97%;
    border-radius: 0px 0px 8px 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    overflow-x: hidden;
    .statistis-container {
      box-sizing: border-box;
      margin: 10px;
      width: calc(100%-20px);
      height: calc(100% - 105px);
      background-color: #f1f4f8;
      .statistics-contaniner-header {
        height: 48px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px 0px 20px;
        border-top: 1px solid #dbe2e7;
        .statistics-contaniner-header-content {
          display: flex;
          .statistics-contaniner-header-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 32px;
            float: right;
            .statistics-contaniner-header-item-title {
              height: 16px;
              width: auto;
              color: #8b97a2;
              font-family: Helvetica;
              font-size: 14px;
              letter-spacing: 0.25px;
              line-height: 16px;
              .statistics-contaniner-header-item-title-value {
                color: #32bcd0;
                margin-left: 10px;
              }
            }
            .statistics-contaniner-header-item-icon {
              color: #8b97a2;
              cursor: pointer;
            }
          }
        }
        .statistics-contaniner-header-btn {
          height: 32px;
          box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
        }
      }
      .statistics-contaniner-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
