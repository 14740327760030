.login-container {
  width: 100%;
  height: 100vh;
  background: #f1f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-card {
    height: 480px;
    width: 540px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
    .login-card-img {
      height: 125px;
      width: 100%;
      background-color: #0d101a;
      object-fit: cover;
    }
    .login-card-content {
      padding: 20px 34px 6px 34px;
      .login-title {
        height: 26px;
        width: 61px;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 26px;
      }
      .login-sub-title {
        height: 16px;
        width: auto;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-top: 8px;
        margin-bottom: 20px;
        text-align: left;
      }
      .login-input {
        margin-bottom: 23px;
      }
      .login-input-error {
        margin-bottom: 23px;
        outline-style: solid;
        outline-width: 2px;
        border-radius: 8px;
        outline-color: #ee5353;
        border: none !important;
      }
      .login-buttons {
        display: flex;
        align-content: center;
        justify-content: space-between;
        height: auto;
        width: 100%;
        .forgot-password {
          height: auto;
          width: auto;
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 16px;
          letter-spacing: 0.35px;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .login-container {
    .login-card {
      min-width: 300px;
      width: 350px;
      .login-card-content {
        .login-buttons {
          display: flex;
          align-content: center;
          justify-content: space-between;
          height: auto;
          width: 100%;
          .forgot-password {
            height: auto;
            width: auto;
            color: #8b97a2;
            font-family: "Product Sans";
            font-size: 14px;
            letter-spacing: 0.35px;
            line-height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
