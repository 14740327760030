.table-container {
  position: relative;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  width: 95.3%;
  margin: 20px 40px 20px 0px;
  .table {
    font-family: "Product Sans";
    width: 100%;
    height: 680px;
    border-radius: 8px;
    background-color: #ffffff;
    border-collapse: collapse;
    border-style: hidden;
    .tr {
      height: 50px;
      background-color: #ffffff;
      box-shadow: 0 1px 0 0 #dee2e7;
      font-family: "Product Sans";
      border-radius: 8px;
      border-bottom: 1px solid #dee2e7;
      .th {
        text-align: left;
        padding: 8px;
        height: 16px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        text-transform: capitalize;
        padding-left: 18px;
        font-weight: normal;
      }
      .td {
        height: 16px;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 16px;
        padding-left: 18px;
        text-align: left;
        svg {
          color: inherit;
        }
        .success-icon {
          width: 24px;
          height: 24px;
          color: #32d094;
        }
        .warning-icon {
          width: 24px;
          height: 24px;
          color: #ff8533;
        }
        .failed-icon {
          width: 24px;
          height: 24px;
          color: #ee5353;
        }
      }
    }
  }
}
.hidden-row {
  display: flex;
  align-items: center;
  .explorer-button {
    height: 44px;
    width: 167px;
    border-radius: 8px;
    background-color: #3c3996;
    box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
    .explorer-button-icon {
      color: #ffffff;
      height: 18;
      width: 18;
      margin-left: 12px;
    }
    .explorer-button-label {
      height: 16px;
      width: 107px;
      color: #ffffff;
      font-family: "Product Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 18px;
      margin-left: 12px;
      margin-right: 16px;
    }
  }
  .description-container {
    height: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 38px;
    .description-title {
      height: 15px;
      width: 342.11px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      margin-bottom: 4px;
      text-align: left;
    }
    .description-value {
      height: 19px;
      width: 342.11px;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 16px;
      letter-spacing: 0.35px;
      line-height: 19px;
      text-align: left;
    }
    .description-value-link {
      height: 16px;
      width: 342.11px;
      color: #3c3996;
      font-family: "Product Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 16px;
      text-align: left;
    }
  }
}
.down-icon {
  height: 5px;
  width: 10px;
  margin-left: 10px;
}
@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}
