.Navbar_wraper {
  height: 70px;
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(36, 36, 36, 0.2) !important;
  position: relative;
  .button_logo {
    width: 300px;
    display: flex;
    margin: 14px 0px 14px 20px;
    border-right: 1px solid #dee2e7;
    .navbar_backbutton {
      height: 43.75px;
      width: 50px;
      background-color: #f0f5f6;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
    }
    .navbar_logo {
      height: 42px;
      width: 165px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      margin-right: 48px;
      img {
        width: 100%;
      }
    }
  }
  .right_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    .navbar_title {
      float: left;
      height: 22px;
      min-width: 200px;
      width: auto;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      padding-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .canvasSaving {
        margin-left: 20px;
        height: 16px;
        width: auto;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        display: flex;
        align-items: center;
      }
    }
    .button_avatar {
      display: flex;
      float: right;
      height: 40px;
      align-items: center;
      align-self: center;
      margin-right: 10px;
      .navbar_button {
        margin: 4px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .button-text-color {
          color: #ffffff;
        }
        .navbar_button_canvas {
          box-sizing: border-box;
          height: 50px;
          width: 109px;
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          background-color: #ffffff;
          box-shadow: 0 1px 4px 0 rgba(9, 12, 32, 0.1);
          margin: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .navbar_button_canvas_title {
            color: #1e2429;
            font-family: "Product Sans";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 16px;
            padding-right: 10px;
          }
        }
        .navbar_button_test {
          height: 50px;
          width: 130px;
          border-radius: 8px;
          background-color: #0d101a;
          box-shadow: -1px 2px 3px 0 rgba(74, 74, 74, 0.1);
          margin: 10px;
          outline: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .navbar_button_test_title {
            height: 16px;
            width: 29px;
            color: #ffffff;
            font-family: "Product Sans";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 16px;
            color: white;
            margin: 5px;
          }
        }
        .navbar_button_deploy {
          height: 50px;
          width: 107px;
          opacity: 0.6;
          border-radius: 8px;
          background-color: #95a1ac;
          margin: 10px;
          outline: none;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .navbar_button_deploy_title {
            height: 16px;
            width: 47px;
            color: #ffffff;
            font-family: "Product Sans";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 16px;
            margin: 5px;
          }
        }
        .navbar_button_share {
          height: 20px;
          width: 28px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px;
          background: none;
          border: none;
          cursor: pointer;
          svg {
            width: 38px;
            height: 28px;
          }
        }
      }
    }
  }
}
