.visual-search-container {
  box-sizing: border-box;
  height: 33px;
  min-width: 226px;
  width: 85%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 16px;
  display: flex;
  align-items: center;
  .visual-search-input {
    border: none;
    outline: none;
    padding-left: 10px;
    height: 28px;
    width: 90%;
  }
  .visual-search-input-icon {
    height: 12.42px;
    width: 12.42px;
    color: #8b97a2;
    margin: 10px;
  }
}
