@import "../../../styles/variables.scss";

.pipelines {
  margin: 8px 40px 0px 40px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background-color: #f1f4f8;
  .cards {
    display: flex;
    padding-bottom: 10px;
    overflow-y: hidden;
    height: auto;
    margin-right: 40px;
  }
}

@media screen and (max-width: 850px) {
  .pipelines {
    margin: 8px 10px 0px 15px;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: #f1f4f8;
    .cards {
      width: 94%;
      display: flex;
      padding-bottom: 10px;
      overflow-y: hidden;
      height: auto;
      margin-right: 40px;
    }
  }
}

@import "../../commonComponents/card/card.scss";
