.model_title {
  color: #1e2429;
  font-family: "Product Sans";
}
.model_subtitle {
  color: #8b97a2;
  font-family: "Product Sans";
}
.model_body {
  display: flex;
  justify-content: center;
  align-items: center;
}
