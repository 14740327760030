.root-ex {
  backdrop-filter: blur(2px);
  .paper-ex {
    height: 651px;
    min-width: 853px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
    .exp-sort-head-container {
      padding: 24px 30px 24px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 853px;
      .exp-sort-head-title {
        height: 32px;
        width: 290px;
        color: #242424;
        font-family: "Sofia Pro";
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }
      .exp-sort-head-checkbox-container {
        height: 20px;
        width: auto;
        display: flex;
        align-items: center;
        .exp-sort-head-checkbox-tilte {
          height: 16px;
          width: 127px;
          color: #0d101a;
          font-family: "Product Sans";
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 16px;
        }
      }
    }
    .exp-sort-content {
      height: 80%;
      width: 100%;
      border-radius: 8px;
      background-color: #f1f5f8;
      box-shadow: 0 1px 4px 0 rgba(36, 36, 36, 0.2);
      padding-bottom: 8px;
      overflow-x: hidden;
      .exp-sort-columns {
        height: 50px;
        width: 100%;
        background-color: #f1f4f8;
        box-shadow: 0 1px 0 0 #c8ced5;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 1px;
        padding: 0px 24px 0px 24px;
        box-sizing: border-box;
        position: sticky;
        top: 0px;
        .exp-sort-column {
          height: 16px;
          width: 20%;
          color: #8b97a2;
          font-family: Helvetica;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
        }
      }
      .exp-added-rows {
        box-sizing: border-box;
        height: 60px;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #dee2e7;
        display: flex;
        align-items: center;
        padding: 0px 24px 0px 24px;
        margin-bottom: 2px;
        cursor: pointer;
        .exp-added-row-item {
          height: 16px;
          width: 20%;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 24px;
          cursor: pointer;
        }
        .exp-sort-item-child {
          display: flex;
          width: 100%;
        }
      }
    }
    .exp-sort-head {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
    .exp-sort-options {
      height: 200px;
      width: 200px !important;
    }
    .export-sort-option {
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 16px;
    }
    .exp-sort-content-bottom {
      display: flex;
      margin-top: 11px;
      margin-bottom: 11px;
      .exp-sort-btns {
        box-sizing: border-box;
        height: 33px;
        min-width: 33px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 0px;
        margin: 5px;
        svg {
          height: 14px;
          width: 14px;
        }
      }
      .exp-sort-btns-text {
        box-sizing: border-box;
        height: 33px;
        width: 57px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        margin: 5px;
        font-size: 14px;
      }
    }
    .exp-menu-item {
      width: 20%;
      .exp-submenu-item {
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 16px;
        background-color: #ffffff;
        border-bottom: 1px solid #dee2e7;
      }
    }
    .onAddSortRowClick {
      background-color: #3c3996 !important;
      div {
        color: #ffffff !important;
        svg {
          color: #ffffff !important;
        }
      }
    }
    .export-sort-icons {
      display: flex;
      flex-direction: column;
      align-items: center;
      .export-sort-icon-up {
        color: #8b97a2;
        margin-bottom: -4px;
        font-size: 20px;
      }
      .export-sort-icon-down {
        color: #8b97a2;
        margin-top: -4px;
        font-size: 20px;
      }
    }
    .exp-options-btn {
      cursor: not-allowed;
      box-shadow: none !important;
    }
  }
}
