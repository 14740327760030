.content-main {
  width: 100%;
  height: 100%;
  background: #f1f4f8;
  overflow-x: hidden;
}
.buttons-container {
  height: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 40px 0px 30px;
  flex-wrap: wrap;
}
.create-new-button {
  height: 50px;
  width: 139px;
  border-radius: 8px;
  background-color: #3c3996;
  box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
  div {
    height: 16px;
    width: 79px;
    color: #ffffff;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.45px;
    line-height: 16px;
  }
  svg {
    color: #ffffff;
    margin-right: 10.12px;
  }
}
.cards-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0px 0px 40px;
  box-sizing: border-box;
}
.card {
  height: 257.09px;
  width: 300px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
  margin: 0px 30px 30px 0px;
}
.card-image {
  height: 155px;
  width: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
  cursor: pointer;
}
.card-data {
  height: 77px;
  width: 277.27px;
  margin: 11px 9px 14px 13px;
  position: relative;
  .card-menu {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
.card-title {
  height: 19px !important;
  width: 254.55px !important;
  color: #1e2429 !important;
  font-family: "Product Sans" !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0.35px !important;
  line-height: 19px !important;
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  float: left !important;
  text-align: start !important;
  cursor: pointer !important;
}
.card-sub-title {
  height: 15px !important;
  width: 272.73px !important;
  color: #8b97a2 !important;
  font-family: "Product Sans" !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 15px !important;
  margin: 8px 0px 0px 0px;
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  float: left !important;
  text-align: start !important;
}
.card-staus-box {
  display: flex;
  margin-top: 19px;
  align-items: center;
}
.card-status {
  height: 16px !important;
  color: #32d094 !important;
  font-family: "Product Sans" !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0.25px !important;
  line-height: 16px !important;
  margin-right: 14px !important;
}
.card-status-normal {
  height: 16px !important;
  width: 34px !important;
  color: #5e7081 !important;
  font-family: "Product Sans" !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0.25px !important;
  line-height: 16px !important;
  margin-right: 14px !important;
}
.card-created {
  height: 15px !important;
  width: 140px !important;
  color: #8b97a2 !important;
  font-family: "Product Sans" !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 15px !important;
}
.option_menu {
  width: 165px;
  margin: 0px;
  padding: 0px;
  .menu-text {
    height: 50px;
    width: 165px;
    text-decoration: none;
    margin: 0px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: #1e2429;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    cursor: pointer;
    border-bottom: 1px solid #d8d8d8;
    &:hover {
      color: #3c3996;
      background-color: #d8d8d8;
    }
  }
  .delete-text {
    height: 50px;
    width: 165px;
    margin: 0px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: crimson;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      background-color: #d8d8d8;
    }
  }
}
@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}
