.snackbar-container {
  border-radius: 8px;
  box-shadow: -1px 2px 3px 0 rgba(74, 74, 74, 0.1);
  color: white;
  border-radius: 20px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  .snackbar_alert {
    border-radius: 8px;
    background-color: #0d101a;
    box-shadow: -1px 2px 3px 0 rgba(74, 74, 74, 0.1);
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    .MuiAlert-action {
      padding-top: 0px;
    }
  }
}
