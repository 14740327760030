.y-popover-container {
  .y-popover-container-item {
    margin-bottom: 4px;
    .y-popover-select-title {
      width: 100%;
      height: 15px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      margin-bottom: 8px;
      text-transform: capitalize;
    }
    .y-popover-select {
      box-sizing: border-box;
      height: 25px;
      width: 185px;
      border: 1px solid #dbe2e7;
      border-radius: 4px;
      background-color: #ffffff;
      color: #8b97a2;
      font-family: Helvetica;
      letter-spacing: 0;
      line-height: 14px;
      outline: none;
    }
    .y-popover-select-option {
      height: 14px;
      width: 26px;
      color: #8b97a2;
      font-family: Helvetica;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  .x-popover-item {
    display: flex;
  }
}

.x-popover-container {
  .x-popover-title-container-bool {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    width: 180px;
    word-break: break-word;
    height: auto;
    text-transform: capitalize;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: capitalize;
    margin-bottom: 4px;
    margin-left: -10px;
  }
  .x-popover-sub-item-bool {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    width: 180px;
    height: auto;
    margin-left: -10px;
  }
  .x-popover-title-container {
    .x-popover-title {
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      text-transform: capitalize;
      margin-bottom: 4px;
    }
    .x-popover-input-checkbox {
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      text-transform: capitalize;
      margin-bottom: 4px;
    }
    .x-popover-sub-item-input {
      margin-top: 4px;
      display: flex;
      width: 185px;
      .x-popover-input {
        box-sizing: border-box;
        height: 25px;
        width: 85%;
        border: 1px solid #dbe2e7;
        border-radius: 4px;
        background-color: #ffffff;
        outline: none;
        text-transform: capitalize;
        color: #8b97a2;
        font-family: Helvetica;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
      }
      .x-popover-input-icon-container {
        height: 23px;
        width: 24px;
        border: 1px solid #dbe2e7;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
        cursor: pointer;
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
    .x-popover-select {
      box-sizing: border-box;
      height: 25px;
      width: 85%;
      border: 1px solid #dbe2e7;
      border-radius: 4px;
      background-color: #ffffff;
      outline: none;
      text-transform: capitalize;
      color: #8b97a2;
      font-family: Helvetica;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
    }
    .x-popover-input-container {
      display: flex;
      width: 185px;
      .x-popover-input {
        box-sizing: border-box;
        height: 25px;
        width: 85%;
        border: 1px solid #dbe2e7;
        border-radius: 4px;
        background-color: #ffffff;
        outline: none;
        text-transform: capitalize;
        color: #8b97a2;
        font-family: Helvetica;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
      }
      .x-popover-input-icon-container {
        height: 23px;
        width: 24px;
        border: 1px solid #dbe2e7;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
        cursor: pointer;
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
