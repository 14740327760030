.workflow_sidebar_wrapper {
  width: 315px;
  height: auto;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  padding-top: 15px;
  overflow-y: scroll;
  .workflow_description_container {
    width: 100%;
    display: flex;
    align-content: space-around;
    justify-content: space-between;
    margin-top: 4px;
    .workflow_description {
      height: 22px;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      margin-left: 19px;
      float: left;
    }
    .workflow_sync_button- {
      margin-right: 15px;
      border: none;
      background-color: white;
      cursor: pointer;
    }
    .workflow_sync_button-transform {
      margin-right: 15px;
      border: none;
      background-color: white;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }
  .workflow_input_container {
    box-sizing: border-box;
    height: 51px;
    width: 261px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: 5px;
    padding-left: 5px;
    .workflow_input {
      height: 25px;
      width: 261px;
      border: none;
      outline: none;
    }
  }
  .workflow_library_container {
    margin-top: 10px;
    .workflow_library_title {
      height: 16px;
      width: 100%;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.25px;
      line-height: 16px;
      display: flex;
      justify-content: flex-start;
    }
    .workflow_node_container {
      max-height: 290px;
      height: auto;
      width: 300px;
      background-color: #f1f4f8;
      box-shadow: 0 1px 0 0 #c8ced5;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow-x: hidden;
      padding-left: 5px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .workflow_node {
      height: 100px;
      width: 80px;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      padding: 5px;
      margin: 5px 8px;
      opacity: 0.9;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      .node_image {
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .node_title {
        height: 30px;
        width: 80px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        overflow: hidden;
        margin-left: 4px;
        margin-right: 4px;
        display: block;
        text-overflow: ellipsis;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
