.exploretab-container {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f1f4f8;
  overflow: hidden;
  box-sizing: border-box;
  .line {
    height: 4px;
    width: 97%;
    border-radius: 8px 8px 0 0;
    background-color: #dbe2e7;
    margin-top: 14px;
    .line1 {
      height: 4px;
      width: 191px;
      border-radius: 8px 0 0 0;
      background-color: #3c3996;
    }
  }
  .dataset-container {
    height: 95.81%;
    width: 97%;
    border-radius: 0px 0px 8px 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 14px;
    overflow-x: hidden;
    .config-container {
      width: 99%;
      height: calc(100% - 100px);
      display: flex;
      margin: 8px;
      .config-container-main {
        width: calc(100% - 277px);
        position: relative;
      }
    }
  }
}
