.submenu ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  .submenu-item {
    height: 32px;
    min-width: 163px;
    box-shadow: 0 1px 0 0 #dee2e7;
    color: #1e2429 !important;
    font-family: Helvetica !important;
    font-size: 14px !important;
    letter-spacing: 0.25px !important;
    line-height: 16px !important;
    margin-bottom: 1px;
    &:hover {
      background-color: #f0f4f8 !important;
      color: #3c3996 !important;
      font-weight: bold !important;
    }
  }
}
.submenu {
  height: 300px;
  width: 163px;
}
