.visual-tableData-left-container {
  height: 100%;
  box-sizing: border-box;
  min-width: 255px;
  flex-basis: 20%;
  border-right: 1px solid #dbe2e7;
  background-color: #f1f4f8;
  display: flex;
  align-items: center;
  flex-direction: column;
  .search-sort-button {
    margin-top: 11px;
    height: 23px;
    width: 100%;
    display: flex;
    .search-sort-button-columns {
      height: 23px;
      width: 50%;
      color: #3c3996;
      font-family: Helvetica;
      font-size: 12px;
      letter-spacing: 0.26px;
      line-height: 14px;
      text-align: center;
      text-transform: capitalize;
      border-bottom: 1px solid #3c3996;
      cursor: pointer;
    }
    .search-sort-button-other {
      height: 23px;
      width: 50%;
      color: #8b97a2;
      font-family: Helvetica;
      font-size: 12px;
      letter-spacing: 0.26px;
      line-height: 14px;
      text-align: center;
      text-transform: capitalize;
      border-bottom: 1px solid #dbe2e7;
      cursor: pointer;
    }
  }

  .all-columns-conatiner {
    width: 100%;
    height: 100%;
    max-height: 745px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    padding-bottom: 8px;
    overflow-x: hidden;
    .all-columns-conatiner-item {
      box-sizing: border-box;
      height: 33px;
      min-width: 226px;
      width: 85%;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      background-color: #ffffff;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 13px;
      letter-spacing: 0.23px;
      line-height: 16px;
      margin-top: 4px;
      padding: 10px;
      text-align: left;
      cursor: move;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .item-icon {
        height: 14px;
        width: 14px;
        margin-right: 6px;
        color: #515fab;
      }
      .item-icon-other {
        height: 14px;
        width: 14px;
        margin-right: 6px;
        color: #29a475;
      }
    }
  }
}
