.config-container-header {
  height: 56px;
  border-left: 1px solid #8b97a2;
  display: flex;
  align-items: center;
  padding: 0px 16px 0px 16px;
  background-color: #f1f4f8;
  .config-container-header-dropdown-text {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .config-container-header-sub-title {
    height: 16px;
    width: 85px;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
    margin: 0px 16px 0px 16px;
  }
  .config-container-search {
    box-sizing: border-box;
    height: 43px;
    width: 326px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    background-color: #f1f4f8;
    display: flex;
    align-items: center;
    .config-container-search-input {
      border: none;
      outline: none;
      background-color: #f1f4f8;
      width: 98%;
    }
    .config-container-search-input-icon {
      margin: 0px 4px 0px 8px;
      color: #8b97a2;
    }
  }
  .config-container-close-icon {
    position: absolute;
    right: 24px;
    color: #8b97a2;
    cursor: pointer;
  }
}
.ex-con-popover-item {
  height: 24px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  .ex-con-popover-item-text {
    height: 16px;
    width: auto;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .ex-con-popover-item-icon {
      font-size: 18px;
      margin-right: 12px;
    }
  }
  .ex-con-popover-item-text:hover {
    color: #3c3996;
  }
  .ex-con-popover-item-text-two {
    height: 16px;
    width: auto;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
    display: flex;
    align-items: center;
    .ex-con-popover-item-icon {
      font-size: 18px;
      margin-right: 12px;
    }
  }
  .ex-con-popover-item-text-two:hover {
    cursor: pointer;
    color: #3c3996;
  }
  .ex-con-popover-item-count {
    height: 16px;
    width: 14px;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
    text-align: right;
  }
}

.root-ex-config {
  backdrop-filter: blur(2px);
  .paper-ex-config {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    width: auto;
    height: 80vh;
    .s-popup-title-container {
      width: auto;
      height: auto;
      padding: 24px 30px 24px 30px;
      position: relative;
      .s-popup-title {
        color: #242424;
        font-family: "Sofia Pro";
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }
      .s-popup-sub-title {
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-top: 8px;
      }
      .s-popup-pca-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .s-popup-pca-icon {
          height: 24px;
          width: 24px;
          color: #8b97a2;
          cursor: pointer;
        }
        .s-popup-pca-title {
          color: #242424;
          font-family: "Sofia Pro";
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
          margin-left: 16px;
        }
      }
      .s-popup-close {
        position: absolute;
        top: 24px;
        right: 30px;
      }
    }
    .s-popup-second-content-card {
      width: 270px;
      .s-popup-second-content-sub-title {
        height: 16px;
        width: 255px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-bottom: 10px;
      }
      .s-popup-second-content-title {
        height: 22px;
        width: auto;
        color: #1e2429;
        font-family: "Product Sans";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
      }
      .s-popup-title-icons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;
        width: 255px;
        .s-popup-title-icons {
          display: flex;
          align-items: center;
          .s-popup-title-icon {
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
      .s-popup-second-content-box {
        height: 428px;
        margin-top: 16px;
        box-sizing: border-box;
        .all-columns-conatiner {
          box-sizing: border-box;
          height: 384px;
          width: 255px;
          background-color: #f1f4f8;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 12px;
          padding-bottom: 8px;
          overflow-x: hidden;
          padding-top: 10px;
          .all-columns-conatiner-item {
            box-sizing: border-box;
            height: 33px;
            min-width: 226px;
            width: 85%;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            background-color: #ffffff;
            color: #8b97a2;
            font-family: "Product Sans";
            font-size: 13px;
            letter-spacing: 0.23px;
            line-height: 16px;
            margin-top: 4px;
            padding: 10px;
            text-align: left;
            cursor: move;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item-icon {
              height: 14px;
              width: 14px;
              margin-right: 6px;
              color: #515fab;
            }
            .item-icon-other {
              height: 14px;
              width: 14px;
              margin-right: 6px;
              color: #29a475;
            }
          }
        }
        .s-popup-second-content-box-tips {
          display: flex;
          margin-top: 10px;
          width: 255px;
          overflow: hidden;
          word-wrap: break-word;
          margin: 10px;
          .s-popup-second-content-box-tips-icon {
            color: #8b97a2;
            height: 14px;
            width: 14px;
            margin: 2px 5px;
          }
          .s-popup-second-content-box-tips-text {
            width: 223px;
            color: #8b97a2;
            font-family: "Product Sans";
            font-size: 12px;
            letter-spacing: 0.21px;
            line-height: 16px;
          }
        }
      }
      .s-popup-second-content-box-two {
        height: 428px;
        width: 255px;
        margin-top: 16px;
        background-color: #f1f4f8;
        box-sizing: border-box;
        padding-top: 8px;
        .s-popup-drag-text {
          color: #8b97a2;
          font-family: "Product Sans" !important;
          padding-top: 50%;
          text-align: center;
          font-size: 13px !important;
        }
        .s-popup-drag-items {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .s-popup-drag-item {
            display: flex;
            box-sizing: border-box;
            height: 33px;
            width: 236px;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            background-color: #ffffff;
            margin-top: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 12px 0px 12px;
            .s-popup-drag-item-title {
              color: #8b97a2;
              font-family: "Product Sans";
              font-size: 13px;
              letter-spacing: 0.23px;
              line-height: 16px;
            }
            .s-popup-drag-item-icon {
              color: #8b97a2;
              cursor: pointer;
            }
          }
        }
      }
    }
    .s-popup-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 20px 14px 20px;
      border-top: 1px solid #dee2e7;
    }
  }
}
