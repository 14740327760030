@import "./utils//fonts//ProductSans.css";
body {
  margin: 0;
  font-family: Product Sans, "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #f1f4f8 !important;
}
* {
  font-family: Product Sans, "Helvetica Neue", sans-serif !important;
  scroll-behavior: smooth;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border-radius: 4px !important;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #fff;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #ccc;
  border-radius: 4px;
}
