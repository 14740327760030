.root {
  backdrop-filter: blur(2px);
  .paper {
    max-height: 590px;
    height: auto;
    width: 570px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
    .upload-title-container {
      padding: 24px 30px 0px 30px;
      position: relative;
      margin: 0px;
      .upload-title {
        height: 32px;
        width: 500px;
        color: #242424;
        font-family: "Sofia Pro";
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }
      .upload-sub-title {
        height: 16px;
        width: 500px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        margin-top: 8px;
      }
    }

    .upload-options {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px;
      box-sizing: border-box;
      .upload-options-basic {
        width: 50%;
        height: 100%;
        color: #3c3996;
        font-family: Helvetica;
        font-size: 14px;
        letter-spacing: 0.31px;
        line-height: 17px;
        text-align: center;
        border-bottom: 1px solid #3c3996;
        padding-bottom: 12px;
        cursor: pointer;
      }
      .upload-options-advanced {
        width: 50%;
        height: 100%;
        color: #8b97a2;
        font-family: Helvetica;
        font-size: 14px;
        letter-spacing: 0.31px;
        line-height: 17px;
        text-align: center;
        border-bottom: 1px solid #8b97a2;
        padding-bottom: 12px;
        cursor: pointer;
      }
    }
    .upload-dropzone {
      box-sizing: border-box;
      height: 195px;
      width: 90%;
      border: 2px dashed #dbe2e7;
      border-radius: 4px;
      margin: 0px 30px 24px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .upload-dropzone-names {
        height: auto;
        width: auto;
        text-align: center;
        .upload-dropzone-names-title {
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
          text-align: center;
        }
        .upload-dropzone-names-sub-title {
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          margin-top: 12px;
          position: relative;
          #file_up {
            position: relative;
            width: 50px;
            height: 16px;
            outline: none;
          }
          #file_up::before {
            content: "browse";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #ffffff;
            color: #3c3996;
            font-size: 14px;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;
            margin-top: auto;
            line-height: 20px;
          }
        }
      }
      .upload-file-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .upload-file-icon {
          color: #3c3996;
          font-size: 80px;
        }
        .upload-file-name {
          color: #3c3996;
          font-size: 14px;
          margin-top: 8px;
          display: flex;
          align-items: center;
          .upload-file-delete {
            margin-left: 8px;
            cursor: pointer;
            height: 14px;
            width: 14px;
          }
        }
      }
    }
    .upload-or {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      .upload-or-text {
        height: 16px;
        width: 165px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 14px;
        letter-spacing: 0.25px;
        line-height: 16px;
        text-align: center;
      }
    }
    .upload-input {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .upload-action-btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 2px solid #dee2e7;
      padding: 8px 20px 8px 20px;
    }
  }
}
