.popup_root {
  height: 370px;
  width: 430px;
  border-radius: 8px;
  background-color: #ffffff;
  .title_area {
    height: 91px;
    width: 378.4px;
    .model_title {
      height: 26px;
      width: 378px;
      color: #1e2429;
      font-family: "Product Sans";
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
    }
    .model_subtitle {
      height: 44px;
      width: 378px;
      color: #8b97a2;
      font-family: "Product Sans";
      font-size: 20px;
      letter-spacing: 0;
      line-height: 22px;
      margin-top: 5px;
    }
  }
  .model_body {
    height: 196px;
    width: 430px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .body_area {
      box-sizing: border-box;
      height: 197px;
      width: 264px;
      border: 1px solid #dbe2e7;
      background-color: #f0f5f6;
    }
  }
}
