.primary-btn {
  box-sizing: border-box;
  height: 50px;
  min-width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  color: #ffffff;
  font-family: "Product Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #3c3996;
  margin: 10px;
  box-shadow: 0 2px 7px 0 rgba(36, 36, 36, 0.2);
  &:hover {
    background-color: #515fab;
    box-shadow: 0 3px 10px 0 rgba(36, 36, 36, 0.2);
  }
}
.secondary-btn {
  box-sizing: border-box;
  height: 50px;
  min-width: 81px;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  color: #ffffff;
  font-family: "Product Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.75px;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #0d101a;
  box-shadow: -1px 2px 3px 0 rgba(74, 74, 74, 0.1);
  margin: 10px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #384550;
  }
}
.default-btn {
  box-sizing: border-box;
  height: 50px;
  border: 1px solid #dee2e7;
  outline: none;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  color: #5e7081;
  font-family: "Product Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  &:hover {
    color: #3c3996;
    background-color: #f1f5f8;
    box-shadow: 0 1px 4px 0 rgba(36, 36, 36, 0.2);
  }
}
.disabled-btn {
  box-sizing: border-box;
  height: 60px;
  border: 1px solid #dee2e7;
  min-width: 140px;
  outline: none;
  padding-left: 18px;
  padding-right: 18px;
  color: #8b97a2;
  font-family: "Product Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #dbe2e7;
  margin: 10px;
  pointer-events: none;
  &:hover {
    pointer-events: none;
  }
}
.disabled-enable-btn {
  box-sizing: border-box;
  height: 60px;
  min-width: 140px;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  color: #ffffff;
  font-family: "Product Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #3c3996;
  margin: 10px;
  &:hover {
    background-color: #515fab;
    box-shadow: 0 3px 10px 0 rgba(36, 36, 36, 0.2);
  }
}
.primary-disabled-btn {
  box-sizing: border-box;
  height: 50px;
  min-width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 18px;
  color: #8b97a2;
  font-family: "Product Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: #dbe2e7;
  margin: 10px;
  pointer-events: none;
}
.neutral-btn {
  padding-left: 18px;
  padding-right: 18px;
  margin: 10px;
  box-sizing: border-box;
  height: 50px;
  min-width: 81px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(9, 12, 32, 0.1);
  cursor: pointer;
  color: #1e2429;
  font-family: "Product Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  &:hover {
    border: 1px solid #dbe2e7;
    border-radius: 8px;
    background-color: #f1f5f8;
    box-shadow: 0 1px 4px 0 rgba(36, 36, 36, 0.2);
  }
}
.cancel-btn {
  height: 50px;
  width: 82px;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
  color: #3c3996;
  font-family: "Product Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
}
