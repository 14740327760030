.root-analyze {
  backdrop-filter: blur(2px);
  .paper-analyze {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
    .vertical-graphContainer {
      height: 365px;
      margin-left: 24px;
    }
    .exp-analyze-table-row-progress {
      height: 50px;
      width: 766px;
      border-radius: 8px 8px 0 0;
      background-color: #f1f4f8;
      box-shadow: 0 1px 0 0 #c8ced5;
    }
    .exp-analyze-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px 16px 30px;
      .exp-analyze-head-title {
        height: 32px;
        width: 290px;
        color: #242424;
        font-family: "Sofia Pro";
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }
      .exp-analyze-head-btns {
        display: flex;
      }
    }
    .exp-analyze-content-head {
      display: flex;
      box-sizing: border-box;
      height: 50px;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;
      border: 1px solid #dbe2e7;
      .exp-analyze-content-head-select {
        padding: 2px;
        color: #8b97a2;
      }
      .exp-analyze-content-head-text {
        padding-left: 12px;
        padding-right: 12px;
        font-family: "Product Sans";
        font-size: 18px;
      }
      .exp-analyze-content-head-text-light {
        padding-left: 12px;
        padding-right: 12px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 18px;
        letter-spacing: 0.39px;
        line-height: 22px;
      }
    }
    .exp-analyze-content-body-one {
      display: flex;
      padding: 24px;
      box-sizing: border-box;
      .exp-analyze-content-body-summary {
        box-sizing: border-box;
        height: 279px;
        width: 329px;
        border: 1px solid #dbe2e7;
        border-radius: 8px;
        padding: 20px;
        .exp-analyze-content-body-summary-head {
          width: 89px;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
          margin-bottom: 16px;
        }
      }
    }
    .exp-analyze-summary-sub-section {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0px 8px 0px 8px;
      .exp-analyze-summary-sub-section-head {
        height: 22px;
        width: auto;
        color: #1e2429;
        font-family: Helvetica !important;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
      }
      .exp-analyze-summary-sub-section-per {
        height: 19px;
        width: 39px;
        color: #0d101a;
        font-family: "Product Sans";
        font-size: 16px;
        letter-spacing: 0.35px;
        line-height: 19px;
        text-align: right;
      }
    }
    .ex-analyze-content-table {
      width: 100%;
      margin-top: 20px;
      .ex-analyze-content-table-body {
        width: 100%;
        .ex-analyze-content-table-row {
          width: 100%;
          margin-bottom: 10px;
          .ex-analyze-content-table-row-cell {
            margin: 0px !important;
            text-transform: capitalize;
            width: 34%;
            color: #1e2429;
            font-family: "Product Sans";
            font-size: 16px;
            letter-spacing: 0.35px;
            line-height: 19px;
            padding: 5px !important;
          }
        }
      }
    }
    .exp-analyze-content-graph {
      height: 263px;
      width: 742.87px;
      margin-left: 24px;
      overflow-y: hidden;
    }
    .exp-analyze-content-statistics {
      box-sizing: border-box;
      height: auto;
      width: auto;
      border: 1px solid #dbe2e7;
      border-radius: 8px;
      margin: 0px 24px 16px 24px;
      display: flex;
      .exp-analyze-nodata {
        text-align: center;
        padding: 16px;
      }
      .exp-analyze-statistics {
        width: 300px;
        padding: 16px 20px 16px 20px;
        .exp-analyze-statistics-head {
          height: 22px;
          width: auto;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 22px;
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .exp-analyze-statistics-body-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          text-transform: capitalize;
          color: #1e2429;
          font-family: "Product Sans";
          font-size: 16px;
          letter-spacing: 0.35px;
          line-height: 19px;
        }
      }
    }
    .exp-analyze-cat-progress-container {
      margin: 0px 0px 24px 16px;
      width: 70%;
      height: 365px;
      overflow: hidden;
      .exp-analyze-cat-progress-container-head {
        box-sizing: border-box;
        height: 50px;
        width: 100%;
        border-radius: 8px 8px 0 0;
        background-color: #f1f4f8;
        box-shadow: 0 1px 0 0 #c8ced5;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        padding-right: 16px;
        .exp-analyze-cat-progress-container-empty {
          width: 70%;
        }
        .exp-analyze-cat-progress-container-column {
          width: 10%;
          height: 16px;
          color: #8b97a2;
          font-family: Helvetica;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
    .exp-analyze-cat-progress-container-items {
      width: 100%;
      overflow-x: hidden;
      height: 100%;
      .exp-analyze-cat-progress-container-item {
        height: 60px;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 #dee2e7;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        padding-right: 16px;
        .exp-analyze-cat-progress-container-item-first {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .exp-analyze-cat-progress-container-item-first-title {
            height: 16px;
            width: 144px;
            color: #1e2429;
            font-family: "Product Sans";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 16px;
            padding-left: 4px;
          }
        }
        .exp-analyze-cat-progress-container-item-r {
          width: 10%;
          text-align: center;
        }
      }
    }
    .statistics-outliers-main {
      width: fit-content !important;
    }
    .statistics-outliers {
      margin-left: 50px;
    }
    .statistics-top-invalids {
      width: 100px !important;
    }
    .morevalues-top-values {
      height: 17px;
      width: auto;
      color: #3c3996;
      font-family: "Product Sans";
      font-size: 14px;
      letter-spacing: 0.31px;
      line-height: 17px;
      cursor: pointer;
    }
  }
}
