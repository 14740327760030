.root-configure {
  backdrop-filter: blur(2px);
  .paper-configure {
    height: 408px;
    width: 530px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 20px 0 rgba(36, 36, 36, 0.25);
    .configure-title-container {
      width: 470px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 30px 20px 30px;
      .configure-title-title {
        height: 36px;
        width: 460px;
        color: #242424;
        font-family: "Sofia Pro";
        font-size: 28px !important;
        font-weight: bold !important;
        letter-spacing: 0 !important;
        line-height: 32px !important;
      }
    }
    .configure-content {
      padding: 0px 30px;
      .configure-drop-down {
        box-sizing: border-box;
        height: auto;
        width: 471px;
        border-radius: 8px;
        position: relative;
        margin-bottom: 16px;
        cursor: pointer;
        .configure-drop-down-title {
          height: 15px;
          width: 439.85px;
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 12px;
          letter-spacing: 0;
          line-height: 15px;
          margin: 10px 6px 0px 20px;
          text-align: left;
        }
        .configure-drop-down-input-holder {
          margin: 0px 10px 13px 20px;
          .configure-drop-down-input {
            outline: none;
            border: none;
            height: 19px;
            caret-color: #3c3996;
            width: 429px;
            color: #1e2429;
            font-family: "Product Sans";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 16px;
            margin-top: 2px;
          }
        }
        .configure-drop-down-down-icon {
          position: absolute;
          top: 30%;
          right: 10px;
          color: #8b97a2;
        }
      }
      .configure-filter-title {
        height: 22px !important;
        width: auto;
        color: #1e2429 !important;
        font-family: "Product Sans";
        font-size: 20px !important;
        font-weight: bold !important;
        letter-spacing: 0 !important;
        line-height: 22px !important;
        margin-top: 16px !important;
      }
      .configure-filter-container {
        display: flex;
        align-items: center;
        margin-left: -10px;
        .configure-filter-text {
          height: 16px;
          width: auto;
          color: #8b97a2;
          font-family: "Product Sans";
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 16px;
        }
      }
    }

    .configure-action-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 14px 20px;
    }
  }
}
.configure-popover-options {
  height: auto;
  width: 470px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 1px 0 0 #c8ced5;
  margin-bottom: 2px;
  padding: 16px 16px 16px 20px;
  cursor: pointer;
  .configure-popover-options-title {
    height: 16px;
    width: auto;
    color: #1e2429;
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .configure-popover-options-description {
    width: 433px;
    color: #8b97a2;
    font-family: "Product Sans";
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }
  &:hover {
    background-color: #f0f4f8 !important;
    color: #3c3996 !important;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
