.draw-graph-axis-main {
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 8px;
  .draw-graph-all-axis-container {
    width: 80%;
    .draw-graph-yaxis-container {
      width: 100%;
      height: 50px;
      height: auto;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-top: 4px;
      .draw-graph-yaxis-title {
        height: 16px;
        width: 33px;
        color: #8b97a2;
        font-family: "Product Sans";
        font-size: 13px;
        letter-spacing: 0.23px;
        line-height: 16px;
        margin-right: 12px;
      }
      .draw-graph-yaxis-box {
        width: 100%;
        height: 40px;
        border: 1px solid #dbe2e7;
        border-radius: 4px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .draw-graph-yaxis-box-title {
          display: flex;
          margin-left: 10px;
          color: #8b97a2;
        }
        .yaxis-body-conatiner {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          height: 37px;
          min-width: 100%;
          overflow-x: hidden;
          .yaxis-child {
            box-sizing: border-box;
            height: 32px;
            width: 218px;
            border: 1px solid rgba(139, 151, 162, 0.25);
            border-radius: 4px;
            background-color: rgba(50, 188, 208, 0.25);
            margin: 0px 4px 0px 4px;
            display: flex;
            align-items: center;
            .yaxis-child-item {
              height: 16px;
              width: 165px;
              color: #5e7081;
              font-family: "Product Sans";
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.25px;
              line-height: 16px;
              display: flex;
            }
            .yaxis-child-close {
              float: right;
              cursor: pointer;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .draw-graph-xaxis-container {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      margin-top: 4px;
      .draw-graph-xaxis-container-main {
        display: flex;
        width: 100%;
        .draw-graph-xaxis {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .draw-graph-xaxis-title {
            height: 16px;
            width: 33px;
            color: #8b97a2;
            font-family: "Product Sans";
            font-size: 13px;
            letter-spacing: 0.23px;
            line-height: 16px;
            margin-right: 16px;
          }
          .draw-graph-xaxis-box {
            width: 100%;
            height: 40px;
            border: 1px solid #dbe2e7;
            border-radius: 4px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .draw-graph-xaxis-box-title {
              display: flex;
              margin-left: 10px;
              color: #8b97a2;
            }
            .xaxis-body-conatiner {
              height: 37px;
              min-width: 100%;
              display: flex;
              align-items: center;
              padding-right: 8px;
              .xaxis-child {
                box-sizing: border-box;
                height: 32px;
                width: 218px;
                border: 1px solid rgba(139, 151, 162, 0.25);
                border-radius: 4px;
                background-color: rgba(175, 214, 133, 0.35);
                display: flex;
                align-items: center;
                .xaxis-child-item {
                  height: 16px;
                  width: 165px;
                  color: #5e7081;
                  font-family: "Product Sans";
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: 0.25px;
                  line-height: 16px;
                  display: flex;
                }
                .xaxis-child-close {
                  float: right;
                  cursor: pointer;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        .draw-graph-xaxis-color {
          display: flex;
          width: 50%;
          align-items: center;
          overflow: hidden;
          .draw-graph-xaxis-color-title {
            height: 16px;
            width: 25px;
            color: #8b97a2;
            font-family: "Product Sans";
            font-size: 13px;
            letter-spacing: 0.23px;
            line-height: 16px;
            text-align: center;
            margin: 0px 10px 0px 10px;
          }
          .draw-graph-xaxis-color-box {
            width: 100%;
            height: 40px;
            border: 1px solid #dbe2e7;
            border-radius: 4px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .draw-graph-xaxis-color-box-title {
              display: flex;
              margin-left: 10px;
              color: #8b97a2;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .xaxis-body-conatiner {
              height: 37px;
              min-width: 100%;
              display: flex;
              align-items: center;
              z-index: 1;
              padding-right: 8px;
              .xaxis-child {
                box-sizing: border-box;
                height: 32px;
                width: 218px;
                border: 1px solid rgba(139, 151, 162, 0.25);
                border-radius: 4px;
                background-color: rgba(175, 214, 133, 0.35);
                display: flex;
                align-items: center;
                .xaxis-child-item {
                  height: 16px;
                  width: 165px;
                  color: #5e7081;
                  font-family: "Product Sans";
                  font-size: 14px;
                  font-weight: bold;
                  letter-spacing: 0.25px;
                  line-height: 16px;
                  display: flex;
                }
                .xaxis-child-close {
                  float: right;
                  cursor: pointer;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .draw-graph-all-axis-btn-container {
    width: 17%;
    .visual-btns {
      height: 40px;
      width: 100%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      font-size: 14px;
      min-width: 110px;
      max-width: auto;
      .visual-btns-icon {
        height: 16px;
      }
    }
  }
}
